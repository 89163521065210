import formatter from '@/mixins/formatter'

export default {

  mixins: [formatter],

  data () {
    return {
      employeeContract: {
        id: null,
        employee_code: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        unit: null,
        department: null,
        position: null,
        employment_status: null,
        address: null,
        items: [],
        item_details: []
      }
    }
  },
  methods: {

    marginY: (top = 0, bottom = 0) => ({ text: ' ', margin: [0, top, 0, bottom] }),

    pageBreak: (pageBreak = 'after') => ({ text: ' ', pageBreak: pageBreak }),

    createContractPDFOptions (options) {
      this.employeeContract = options.employee

      return this.$pdfmake.createPdf(
        {
          pageSize: 'Legal',
          pageMargins: [40, 75, 40, 75],
          info: {
            title: options.info.title,
            author: options.info.author,
            creator: options.info.creator,
            subject: options.info.subject
          },
          defaultStyle: {
            font: 'Lato',
            fontSize: 10,
            alignment: 'justify',
            lineHeight: 1.5
          },
          header: [
            {
              image: 'logo',
              alignment: 'right',
              margin: [0, 30, 18, 0],
              fit: [90, 40]
            }
          ],
          content: [
            this.pageOne(),
            this.pageTwo(),
            this.assignedItems(),
            this.signatures()
          ],
          footer: function (currentPage, pageCount) {
            return {
              stack: [
                { canvas: [{ type: 'line', x1: 40, y1: 0, x2: 560, y2: 0, lineHeight: 2, lineWidth: 1, color: 'green' }] },
                { canvas: [{ type: 'line', x1: 40, y1: 0, x2: 560, y2: 0, lineHeight: 2, lineWidth: 1, color: 'green' }] },
                {
                  text: process.env.VUE_APP_COMPANY_TITLE,
                  margin: [40, 10, 0, 0],
                  style: 'footer'
                },
                { text: process.env.VUE_APP_COMPANY_ADDRESS, style: 'footer' },
                {
                  columns: [
                    {
                      text: process.env.VUE_APP_COMPANY_NUMBER
                    },
                    {
                      text: `${currentPage} of ${pageCount}`,
                      alignment: 'right'
                    }
                  ],
                  style: 'footer'
                }
              ]
            }
          },
          images: {
            logo: options.logo ? options.logo : `${window.location.origin}/img/invalid-image.png`,
            invalidImage: `${window.location.origin}/img/invalid-image.png`
          },
          styles: {
            header: {
              margin: [40, 0, 0, 0]
            },
            footer: {
              fontSize: 9,
              margin: [40, 0, 40, 0]
            },
            title: {
              bold: true,
              alignment: 'center',
              fontSize: 12
            },
            subTitle: {
              bold: true,
              margin: [0, 10, 0, 10]
            },
            normalFont: {
              alignment: 'justify'
            }
          }
        },
        null,
        {
          Lato: {
            normal: `${window.location.origin}/assets/lato/Lato-Regular.ttf`,
            bold: `${window.location.origin}/assets/lato/Lato-Bold.ttf`,
            italics: `${window.location.origin}/assets/lato/Lato-Italic.ttf`,
            bolditalics: `${window.location.origin}/assets/lato/Lato-BoldItalic.ttf`
          }
        }
      )
    },

    pageOne () {
      return {
        stack: [
          {
            text: 'MEMORANDUM OF AGREEMENT IN THE ISSUANCE OF LAPTOP',
            style: 'title'
          },
          {
            text: 'KNOW ALL MEN BY THESE PRESENTS: ',
            style: 'subTitle'
          },
          {
            text: [
              {
                text: 'ALJAY AGRO-INDUSTRIAL SOLUTIONS, INC.',
                decoration: 'underline',
                bold: true
              },
              ', a corporation, duly organized and existing under the Philippine'
            ],
            alignment: 'justify',
            margin: [30, 0, 0, 0]
          },
          {
            text: [
              'Laws with office address located at ',
              'Purok Maligaya, Batal, Santiago City, ',
              'represented herein by its President,',
              'Kris Kristoffer C. Alejandro, MBA, ',
              'hereafter referred as the ',
              {
                text: '"COMPANY"',
                bold: true
              },
              '.'
            ],
            alignment: 'justify'
          },
          {
            text: ' -And- ',
            alignment: 'center',
            margin: [0, 10, 0, 10]
          },
          {
            text: [
              {
                text: `${this.employeeContract.first_name} ${this.employeeContract.middle_name ? this.employeeContract.middle_name : ''} ${this.employeeContract.last_name}`,
                decoration: 'underline',
                bold: true
              },
              ', a bonafide employee, hereafter referred as the ',
              {
                text: '"CUSTODIAN"',
                bold: true
              },
              ' with the'
            ],
            alignment: 'justify',
            margin: [30, 0, 0, 0]
          },
          {
            text: [
              {
                text: [
                  'following employment status of ',
                  {
                    text: this.employeeContract.employment_status,
                    decoration: 'underline',
                    bold: true
                  },
                  ' and having the position of ',
                  {
                    text: this.employeeContract.position,
                    decoration: 'underline',
                    bold: true
                  },
                  ' under the department of ',
                  {
                    text: this.employeeContract.department,
                    decoration: 'underline',
                    bold: true
                  },
                  ' and unit of ',
                  {
                    text: this.employeeContract.unit,
                    decoration: 'underline',
                    bold: true
                  },
                  ', with residential address at ',
                  {
                    text: this.employeeContract.address,
                    decoration: 'underline'
                  },
                  '.'
                ],
                alignment: 'justify'
              }
            ]
          },
          {
            text: 'WITNESSETH:',
            bold: true,
            alignment: 'center',
            margin: [0, 10, 0, 10]
          },
          {
            alignment: 'justify',
            margin: [30, 0, 0, 0],
            text: [
              {
                text: 'WHEREAS',
                bold: true
              },
              ', the ',
              {
                text: 'COMPANY',
                bold: true
              },
              ' offers employees different types of assets to be used in their place of work. '
            ]
          },
          {
            alignment: 'justify',
            margin: [30, 0, 0, 0],
            text: [
              {
                text: 'WHEREAS',
                bold: true
              },
              ', the assets offers mobility fo the ',
              {
                text: 'CUSTODIAN',
                bold: true
              },
              ', which may have advantage for his/her work but'
            ]
          },
          {
            alignment: 'justify',
            text: [
              'remains the property of the ',
              {
                text: 'COMPANY',
                bold: true
              },
              '. The provision of the assets is related to its current status and could change if the conditions of the employment change.'
            ]
          }
        ]
      }
    },

    pageTwo () {
      return {
        stack: [
          {
            alignment: 'justify',
            margin: [30, 0, 0, 5],
            text: [
              {
                text: 'WHEREAS',
                bold: true
              },
              ', the ',
              {
                text: 'BOTH PARTIES',
                bold: true
              },
              ', agree to the terms of use as outlined in this document.'
            ]
          },
          {
            alignment: 'justify',
            margin: [30, 0, 0, 0],
            text: [
              {
                text: 'NOW',
                bold: true
              },
              ', ',
              {
                text: 'THEREFORE',
                bold: true
              },
              ', for and in consideration of the foregoing premises and the mutual convenants hereinafter'
            ]
          },
          'set for that both parties have agreed subject to the following terms and conditions, to wit:',
          {
            type: 'upper-roman',
            margin: [30, 0, 0, 0],
            ol: [
              this.periodOfUsefulLife(),
              this.termsAndConditions(),
              this.sanctionForTheNegligenceOfCustodian(),
              this.rescissionOfContract()
            ]
          }
        ]
      }
    },

    periodOfUsefulLife () {
      return {
        stack: [
          {
            text: 'PERIOD OF USEFUL LIFE',
            margin: [0, 10, 0, 0],
            decoration: 'underline'
          },
          {
            margin: [25, 5, 0, 0],
            type: 'lower-alpha',
            ol: [
              {
                text: [
                  'The period of minimum useful life shall be a period of 3 YEARS (36) months.'
                ]
              },
              {
                text: 'Useful life shall be the basis for the computation of market value versus the book value only.'
              },
              {
                text: [
                  'The IT Department shall assess the performance and usability of the laptop computer within or after its ',
                  'useful life and may recommend possible options to ensure employee\'s productivity and uninterrupted ',
                  'working operation.'
                ]
              }
            ]
          }
        ]
      }
    },

    termsAndConditions () {
      return {
        stack: [
          {
            text: 'TERMS AND CONDITIONS',
            margin: [0, 10, 0, 0],
            decoration: 'underline'
          },
          {
            margin: [25, 5, 0, 0],
            type: 'lower-alpha',
            ol: [
              this.responsibilitiesOfCustodian(),
              this.responsibilitiesOfCompany()
            ]
          }
        ]
      }
    },

    responsibilitiesOfCustodian () {
      return {
        stack: [
          {
            text: 'Roles and Responsibilities of the CUSTODIAN:',
            margin: [0, 0, 0, 0],
            decoration: 'underline'
          },
          {
            margin: [25, 5, 0, 0],
            type: 'lower-alpha',
            ol: [
              'The employee being the CUSTODIAN required to affixed his/her signature on this agreement;',
              'By affixing his/her signature on this agreement, it is considered that he/she received the laptop in good working condition.',
              {
                text: [
                  'The laptop computer shall be used in accordance with existing policies established by the ',
                  'COMPANY, presently and in the future, any liability arising for any breach or disciplinary ',
                  'action shall be treated without prejudice;'
                ]
              },
              {
                text: [
                  'The laptop computer is issued to the CUSTODIAN for work - related jobs and/ or',
                  'professional development purposes. He / she is responsible to always bring the laptop computers and',
                  'its accessories in the office, assigned workstation or anywhere, remotely assigned thereto.'
                ]
              },
              {
                text: [
                  'He / she must set a security password at all times and be responsible for safeguarding',
                  'the stored data or access over the laptop in google drive or authorized external storage on a',
                  'daily basis for backup purposes.The IT Department shall not be responsible for any loss of data.'
                ]
              },
              {
                text: [
                  'He/she is not allowed to install or uninstall any software/program or any hardware',
                  'repairs over the laptop unless approved by the IT Department.'
                ]
              },
              {
                text: [
                  'Unauthorized modification on the software and hardware will hold the CUSTODIAN liable for ',
                  'loss of data and warranties.'
                ]
              },
              {
                text: [
                  'Any repair / system error occurs, the CUSTODIAN shall execute a request through IT ',
                  'Ticketing System shall be made on the modification of software, programs, repairs or ',
                  'any technical assistance when they are needed.'
                ]
              },
              {
                text: [
                  'He / she is responsible for taking reasonable precautions to secure the laptop from ',
                  'unauthorized use, damage and theft.'
                ]
              },
              {
                stack: [
                  'In case of loss, theft and damage of the laptop and / or its accessories he / she must undergo ',
                  'the following actions:',
                  {
                    margin: [25, 5, 0, 0],
                    ol: [
                      {
                        text: [
                          'He / she must notify his/her direct superior and the IT Department within 24 hours.'
                        ]
                      },
                      {
                        text: [
                          'An incident report or copy of filed police blotter (whichever is deemed ',
                          'necessary) must be submitted to the HR Department(copy furnished IT Department) ',
                          'indicating the cause of loss, damage or theft within the next 48 hours for assessment.'
                        ]
                      },
                      {
                        text: [
                          'Failure to provide notification and report shall make the CUSTODIAN liable in full ',
                          'replacement value of the specified laptop and accessories.'
                        ]
                      },
                      {
                        text: [
                          'After the investigation and it is determined to be caused by negligence or ',
                          'intentional misuse, the CUSTODIAN shall assume full responsibility under Section III, ',
                          'SANCTION FOR THE NEGLIGENCE / OMISSION OF CUSTODIAN stated below.'
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                text: [
                  'If the CUSTODIAN retires, resigns, is terminated or disengaged with the COMPANY, the',
                  'laptop and all its accessories must be returned to the IT Department except, if it is awarded to',
                  'him/ her provided duly supported by an approved letter from the COMPANY.If he / she fails to',
                  'return the laptop and its accessories, he/ she shall be liable to pay based on the fair market',
                  'value or book value whichever is higher.'
                ]
              },
              {
                text: [
                  'If the CUSTODIAN shall be reassigned to another position or transferred to another unit within',
                  'the employment period, an assessment shall be performed by the IT Department on the',
                  'compatibility of specifications to new work assignments.The laptop and all its accessories',
                  'must be returned to the IT Department and a new MOA shall be made, otherwise only the',
                  'update on the working status shall be necessary.'
                ]
              },
              {
                stack: [
                  'He/she is authorized to lend the laptop to other Aljay employees given the following circumstances;',
                  {
                    margin: [25, 5, 0, 0],
                    ol: [
                      {
                        text: [
                          'The requester secured approval through email from the immediate supervisor copy',
                          'furnish IT Department.The liability thereof rest to the requestee, in case of the',
                          'negligence of the laptop; '
                        ]
                      },
                      {
                        text: [
                          'In case he/she lend the laptop without approval, both are solidarity liable for the negligence of the laptop; '
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                text: [
                  'The laptop computer will need to be surrendered to the IT Department for scheduled quarterly maintenance and upgrades.'
                ]
              }
            ]
          }
        ]
      }
    },

    responsibilitiesOfCompany () {
      return {
        stack: [
          {
            text: 'Roles and Responsibilities of the COMPANY:',
            margin: [0, 10, 0, 0],
            decoration: 'underline'
          },
          {
            margin: [25, 5, 0, 0],
            type: 'lower-alpha',
            ol: [
              'The agreement is duly represented by the IT Department, in behalf of the Company;',
              'The IT Department shall be responsible for the procurement of laptop computers and its accessories;',
              {
                text: [
                  'The IT Department shall furnish a copy of agreement to both PARTIES and to be print-out in ',
                  'four (4) copies, such documents shall be kept for documentation by Legal Counsel, Legal Officer, IT Department &  Employee 201-File.'
                ]
              },
              'Prior to issuance of laptops, all CUSTODIAN will receive basic care and usage training.',
              {
                text: [
                  'The IT Department shall strictly maintain and update the status of the laptop in the IT Asset ',
                  'Management System (IAMS) to keep track of the  laptop computers and custodian.'
                ]
              },
              'The IT Department shall uphold and execute the integrity of established policies and procedures, presently and in the future.',
              'The IT Department shall follow established technical support protocols to ensure laptop computers are accommodated and working properly.',
              {
                text: [
                  'The IT Department shall ensure to manage their function and privileges as ADMINISTRATOR to all ',
                  'the computers to protect the best interest of the COMPANY, thus shall equip the computers with security features and techniques that will minimize loss of data.'
                ]
              },
              {
                stack: [
                  'In case the laptop or its accessories are stolen,  lost, damaged or subject for repairs, the IT Department will determine appropriate liability and replacement responsibility to wit:',
                  {
                    margin: [25, 5, 0, 0],
                    ol: [
                      'Examination on laptop computers shall need to be performed first to assess if damage is covered by warranties.',
                      'Beyond warranties, any replacement of parts and cost of repair shall be shouldered by the COMPANY with the usual wear and tear provided duly notified and reported to IT Department;',
                      'Partial damage due to negligence or omission of the CUSTODIAN, shall hold the latter liable in the full amount of the cost of repair or replacement of parts. Warranty applies within 1 year of issuance. Any damage out of scope of warranty will be charged to the this.employeeContract.',
                      'Total damage, stolen or lost due to negligence or omission of the CUSTODIAN, shall hold the latter liable in accordance with Section III, SANCTION FOR THE NEGLIGENCE/OMISSION OF CUSTODIAN stated below.'
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    },

    sanctionForTheNegligenceOfCustodian () {
      return {
        stack: [
          {
            text: 'SANCTION FOR THE NEGLIGENCE/OMISSION OF CUSTODIAN:',
            margin: [0, 10, 0, 0],
            decoration: 'underline'
          },
          {
            margin: [25, 5, 0, 0],
            type: 'lower-alpha',
            ol: [
              'If within 1 year of issuance, 75% payment will be charged to the employee;',
              'If within 2 years of issuance, 50% payment will be charged to the employee;',
              'If within 3 years of issuance, 25% payment will be charged to the employee;',
              'If more than 3 years, 15% payment will be charged to the employee based on the purchase value.'
            ]
          }
        ]
      }
    },

    rescissionOfContract () {
      return {
        stack: [
          {
            text: 'RESCISSION OF CONTRACT:',
            margin: [0, 10, 0, 0],
            decoration: 'underline'
          },
          {
            margin: [25, 5, 0, 0],
            type: 'lower-alpha',
            ol: [
              'The COMPANY being the owner of property shall have full discretion to cancel the contract at any time, if it is deemed necessary.',
              'The COMPANY shall be free from any liability arising from the acts or omission of the CUSTODIAN except for losses or damages due to force majeure. '
            ]
          }
        ]
      }
    },

    assignedItems () {
      const border = [false, false, false, false]

      const itemsOfEmployee = []
      const itemDetailsOfEmployee = []

      this.employeeContract.items.forEach(item => {
        itemsOfEmployee.push(
          [
            { text: item.item_code, border: border },
            { text: item.category_name, border: border },
            { text: item.serial_number, border: border },
            { text: item.original_cost, border: border, alignment: 'right' }
          ]
        )

        if (item.specifications) {
          itemsOfEmployee.push(
            [
              {
                colSpan: 4,
                text: item.specifications,
                border: [false, false, false, true],
                margin: [0, 0, 0, 10]
              },
              '',
              '',
              ''
            ]
          )
        }
      })

      this.employeeContract.item_details.forEach(item => {
        itemDetailsOfEmployee.push(
          [
            { text: item.item_detail_code, border: border },
            { text: item.category_name, border: border },
            { text: item.serial_number, border: border },
            { text: item.original_cost, border: border, alignment: 'right' }
          ]
        )

        if (item.specifications) {
          itemDetailsOfEmployee.push(
            [
              { colSpan: 2, text: '', border: [false, false, false, true] },
              '',
              {
                colSpan: 2,
                text: item.specifications,
                border: [false, false, false, true],
                margin: [0, 0, 0, 10]
              },
              ''
            ]
          )
        }
      })

      return {
        stack: [
          {
            text: 'The lists of Assets that the company offers to this custodian: ',
            bold: true
          },
          {
            margin: [0, 10, 0, 10],
            table: {
              layout: 'headerLineOnly',
              headerRows: 1,
              widths: ['25%', '20%', '35%', '15%'],
              body: [
                [
                  {
                    text: 'Item Code',
                    bold: true,
                    border: [false, false, false, true]
                  },
                  {
                    text: 'Category',
                    bold: true,
                    border: [false, false, false, true]
                  },
                  {
                    text: 'Serial Number',
                    bold: true,
                    border: [false, false, false, true]
                  },
                  {
                    text: 'Cost',
                    bold: true,
                    border: [false, false, false, true],
                    alignment: 'right'
                  }
                ],
                ...itemsOfEmployee.map(row => [row[0], row[1], row[2], row[3]]),
                ...itemDetailsOfEmployee.map(row => [row[0], row[1], row[2], row[3]])
              ]
            }
          }
        ]
      }
    },

    signatures () {
      return {
        stack: [
          {
            text: [
              'This Agreement contains the entire understanding of the parties relating to the subject matter hereof'
            ],
            margin: [25, 0, 0, 0]
          },
          'superseding all prior communications between the parties whether verbal or written, and this Agreement may not be',
          'altered, amended, modified or otherwise changed nor may any of the terms hereof be waived, except by a written instrument executed by both parties.',
          {
            margin: [25, 10, 0, 0],
            text: [
              {
                text: 'IN WITNESS WHEREOF',
                bold: true
              },
              ', the parties hereto have caused these presents to be executed on ',
              {
                text: '                                              ',
                decoration: 'underline'
              }
            ]
          },
          {
            text: [
              ' at ',
              {
                text: 'PUROK MALIGAYA, BATAL, SANTIAGO CITY',
                decoration: 'underline'
              },
              '.'
            ]
          },
          {
            table: {
              layout: 'noBorders',
              widths: ['50%', '50%'],
              body: [
                [
                  {
                    stack: [
                      {
                        text: 'KRIS KRISTOFFER C. ALEJANDRO, MBA',
                        bold: true
                      },
                      {
                        canvas: [
                          {
                            type: 'line',
                            x1: 200,
                            x2: 0,
                            y1: 1,
                            y2: 1,
                            lineWidth: 1
                          }
                        ]
                      },
                      {
                        text: 'PRESIDENT',
                        margin: [0, 7, 0, 0]
                      }
                    ],
                    alignment: 'center',
                    border: [false, false, false, false]
                  },
                  {
                    stack: [
                      {
                        text: (`${this.employeeContract.first_name} ${this.employeeContract.middle_name ? this.employeeContract.middle_name : ''} ${this.employeeContract.last_name}`).toLowerCase().toUpperCase(),
                        bold: true
                      },
                      {
                        canvas: [
                          {
                            type: 'line',
                            x1: 200,
                            x2: 0,
                            y1: 1,
                            y2: 1,
                            lineWidth: 1
                          }
                        ]
                      },
                      {
                        text: 'EMPLOYEE',
                        margin: [0, 7, 0, 0]
                      }
                    ],
                    alignment: 'center',
                    border: [false, false, false, false]
                  }
                ]
              ]
            },
            margin: [0, 10, 0, 10]
          },
          {
            text: 'SIGNED IN THE PRESENCE OF',
            alignment: 'center',
            margin: [0, 0, 0, 10]
          },
          {
            table: {
              layout: 'noBorders',
              widths: ['50%', '50%'],
              body: [
                [
                  {
                    stack: [
                      {
                        text: 'MA. JANICE DAE L. SAYON',
                        bold: true
                      },
                      {
                        canvas: [
                          {
                            type: 'line',
                            x1: 200,
                            x2: 0,
                            y1: 1,
                            y2: 1,
                            lineWidth: 1
                          }
                        ]
                      }
                    ],
                    alignment: 'center',
                    border: [false, false, false, false]
                  },
                  {
                    stack: [
                      {
                        text: 'HARVIE A. BAÑARES',
                        bold: true
                      },
                      {
                        canvas: [
                          {
                            type: 'line',
                            x1: 200,
                            x2: 0,
                            y1: 1,
                            y2: 1,
                            lineWidth: 1
                          }
                        ]
                      }
                    ],
                    alignment: 'center',
                    border: [false, false, false, false]
                  }
                ]
              ]
            }
          }
          // {
          //   text: 'ACKNOWLEDGEMENT',
          //   alignment: 'center',
          //   margin: [0, 10, 0, 10],
          //   bold: true
          // },
          // {
          //   text: 'REPUBLIC OF THE PHILIPPINES)',
          //   margin: [0, 10, 0, 10],
          //   bold: true
          // },
          // {
          //   text: [
          //     {
          //       text: '_____________________________________'
          //     },
          //     ')S.S'
          //   ]
          // },
          // {
          //   margin: [25, 0, 0, 0],
          //   text: [
          //     {
          //       text: 'BEFORE ME',
          //       bold: true
          //     },
          //     {
          //       text: ', a Notary Public for and this day personally appeared the following with their corresponding proofs'
          //     }
          //   ]
          // },
          // 'of identity:',
          // {
          //   table: {
          //     layout: 'noBorders',
          //     widths: ['50%', '50%'],
          //     body: [
          //       [
          //         {
          //           stack: [
          //             {
          //               text: 'KRIS KRISTOFFER C. ALEJANDRO, MBA'
          //             },
          //             {
          //               canvas: [
          //                 {
          //                   type: 'line',
          //                   x1: 200,
          //                   x2: 0,
          //                   y1: 1,
          //                   y2: 1,
          //                   lineWidth: 1
          //                 }
          //               ]
          //             },
          //             {
          //               text: (`${this.employeeContract.first_name} ${this.employeeContract.middle_name ? `${this.employeeContract.middle_name.charAt(0)}.` : ''} ${this.employeeContract.last_name}`).toLowerCase().toUpperCase(),
          //               margin: [0, 7, 0, 0]
          //             }
          //           ],
          //           alignment: 'center',
          //           border: [false, false, false, false]
          //         },
          //         {
          //           stack: [
          //             {
          //               text: 'PERSONALLY KNOWN'
          //             },
          //             {
          //               canvas: [
          //                 {
          //                   type: 'line',
          //                   x1: 200,
          //                   x2: 0,
          //                   y1: 1,
          //                   y2: 1,
          //                   lineWidth: 1
          //                 }
          //               ]
          //             },
          //             {
          //               text: this.employeeContract.employee_code,
          //               margin: [0, 7, 0, 0]
          //             }
          //           ],
          //           alignment: 'center',
          //           border: [false, false, false, false]
          //         }
          //       ]
          //     ]
          //   },
          //   margin: [0, 10, 0, 10]
          // },
          // {
          //   margin: [25, 0, 0, 0],
          //   text: 'To me known to be the same persons who signed and executed the foregoing Agreement and each acknowledged'
          // },
          // 'to me that the same is executed as their free and voluntary act and deed.',
          // {
          //   margin: [25, 10, 0, 0],
          //   text: 'I further certify that said document consists of four(4) pages including this acknowledgement page and was '
          // },
          // 'signed by the above - named parties and the witnesses on all the pages thereof.',
          // {
          //   margin: [25, 10, 0, 0],
          //   text: [
          //     {
          //       text: 'IN WITNESS WHEREOF',
          //       bold: true
          //     },
          //     ', I have hereunto affixed my signature and notarial seal in the',
          //     ' ____________________________ ,'
          //   ]
          // },
          // 'this _____________________________ .',
          // {
          //   text: 'NOTARY PUBLIC',
          //   alignment: 'right'
          // },
          // {
          //   text: [
          //     'Doc. No.  ',
          //     '__________'
          //   ]
          // },
          // {
          //   text: [
          //     'Page No.  ',
          //     '__________'
          //   ]
          // },
          // {
          //   text: [
          //     'Book No.  ',
          //     '__________'
          //   ]
          // },
          // 'Series of 2023'
        ]
      }
    }
  }
}

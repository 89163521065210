import formatter from '@/mixins/formatter'
import store from '@/store'

export default {

  mixins: [formatter],

  data () {
    return {
      notedByEmployees: [],
      employeeIssuance: {
        id: null,
        employee_code: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        unit: null,
        department: null,
        position: null,
        employment_status: null,
        address: null,
        issuance_number: null,
        issuance_date: null,
        items: [],
        item_details: []
      }
    }
  },

  methods: {

    marginY: (top = 0, bottom = 0) => ({ text: ' ', margin: [0, top, 0, bottom] }),

    pageBreak: (pageBreak = 'after') => ({ text: ' ', pageBreak: pageBreak }),

    createIssuancePDFOptions (options) {
      this.employeeIssuance = options.employee
      this.notedByEmployees = options.notedByEmployees

      return this.$pdfmake.createPdf(
        {
          pageSize: 'A4',
          // pageOrientation: 'landscape',
          pageMargins: [40, 90, 40, 75],
          info: {
            title: options.info.title,
            author: options.info.author,
            creator: options.info.creator,
            subject: options.info.subject
          },
          defaultStyle: {
            font: 'Lato',
            fontSize: 10,
            alignment: 'justify',
            lineHeight: 1.5
          },
          header: [
            // {
            //   image: 'logo',
            //   alignment: 'center',
            //   margin: [0, 10, 0, 0],
            //   fit: [90, 40]
            // },
            // {
            //   text: options.info.title,
            //   margin: [0, 5, 0, 0],
            //   alignment: 'center'
            // },
            {
              layout: 'noBorders',
              table: {
                headerRows: 1,
                widths: ['23.39%', '50%', '26.61%'],
                body: [
                  [
                    { rowSpan: 3, image: 'logo', fit: [100, 50] },
                    { colSpan: 1, text: process.env?.VUE_APP_COMPANY_TITLE?.toUpperCase(), fontSize: 10, alignment: 'center' },
                    { rowSpan: 3, text: `No. ${options.employee.issuance_number}`, alignment: 'right', margin: [10, 20] }
                  ],
                  [
                    '', { colSpan: 1, text: process.env.VUE_APP_COMPANY_ADDRESS, fontSize: 10, alignment: 'center' }, ''
                  ],
                  [
                    '', { colSpan: 1, text: options.info.title, alignment: 'center', fontSize: 15, bold: true }, ''
                  ]
                ]
              },
              margin: [35, 15]
            }
          ],
          content: [
            this.issuanceItems()
          ],
          footer: {
            stack: [
              { canvas: [{ type: 'line', x1: 40, y1: 0, x2: 560, y2: 0, lineHeight: 2, lineWidth: 1, color: 'green' }] },
              { canvas: [{ type: 'line', x1: 40, y1: 0, x2: 560, y2: 0, lineHeight: 2, lineWidth: 1, color: 'green' }] },
              {
                text: process.env.VUE_APP_COMPANY_TITLE,
                style: 'footer',
                margin: [40, 10, 0, 0]
              },
              { text: process.env.VUE_APP_COMPANY_ADDRESS, style: 'footer' },
              { text: process.env.VUE_APP_COMPANY_NUMBER, style: 'footer' }
            ]
          },
          images: {
            logo: options.logo ? options.logo : `${window.location.origin}/img/invalid-image.png`,
            invalidImage: `${window.location.origin}/img/invalid-image.png`
          },
          styles: {
            header: {
              margin: [40, 0, 0, 0]
            },
            footer: {
              fontSize: 9,
              margin: [40, 0, 0, 0]
            },
            title: {
              bold: true,
              alignment: 'center',
              fontSize: 12
            },
            subTitle: {
              bold: true,
              margin: [0, 10, 0, 10]
            },
            normalFont: {
              alignment: 'justify'
            }
          }
        },
        null,
        {
          Lato: {
            normal: `${window.location.origin}/assets/lato/Lato-Regular.ttf`,
            bold: `${window.location.origin}/assets/lato/Lato-Bold.ttf`,
            italics: `${window.location.origin}/assets/lato/Lato-Italic.ttf`,
            bolditalics: `${window.location.origin}/assets/lato/Lato-BoldItalic.ttf`
          }
        }
      )
    },

    issuanceItems () {
      const user = store.getters['auth/user']

      const border = [true, true, true, true]

      const noBorder = [false, false, false, false]

      const itemDetailsOfEmployee = []

      const allItems = []

      if (this.employeeIssuance.items.length > 0) {
        this.employeeIssuance.items.forEach(item => {
          allItems.push(
            [
              { text: 1, border: border },
              { text: 'PC', border: border },
              { text: item.template_name, border: border },
              { text: item.serial_number }
            ]
          )

          this.employeeIssuance.item_details.filter(
            itemDetail => itemDetail.is_package === 0 && itemDetail.item_id === item.item_id
          ).forEach(itemDetail => {
            allItems.push(
              [
                { text: 1, border: border },
                { text: 'PC', border: border },
                { text: itemDetail.category_name, border: border },
                { text: itemDetail.serial_number }
              ]
            )
          })
        })
      } else {
        this.employeeIssuance.item_details.filter(
          itemDetail => itemDetail.is_package === 0
        ).forEach(itemDetail => {
          allItems.push(
            [
              { text: 1, border: border },
              { text: 'PC', border: border },
              { text: itemDetail.category_name, border: border },
              { text: itemDetail.serial_number }
            ]
          )
        })
      }

      const employeeName = `${this.employeeIssuance.first_name} ${this.employeeIssuance.middle_name ? this.employeeIssuance.middle_name : ''} ${this.employeeIssuance.last_name}`

      return {
        stack: [
          {
            margin: [0, 5, 0, 5],
            table: {
              layout: 'headerLineOnly',
              headerRows: 1,
              widths: ['17%', '43%', '7%', '33%'],
              body: [
                [
                  {
                    text: 'REQUESTED BY:',
                    bold: true,
                    border: noBorder
                  },
                  {
                    text: employeeName,
                    border: noBorder
                  },
                  {
                    text: 'DATE:',
                    bold: true,
                    border: noBorder
                  },
                  {
                    // text: this.dateTimeLongFormatter(Date.now()),
                    text: this.dateTimeLongFormatter(this.employeeIssuance.issuance_date),
                    border: noBorder
                  }
                ],
                [
                  {
                    text: 'JOB TITLE:',
                    bold: true,
                    border: noBorder
                  },
                  {
                    text: this.employeeIssuance.position,
                    border: noBorder,
                    colSpan: 3
                  }
                ]
              ]
            }
          },
          {
            table: {
              layout: 'headerLineOnly',
              headerRows: 1,
              widths: ['7.5%', '7.5%', '50%', '35%'],
              body: [
                [
                  {
                    text: 'QTY',
                    bold: true
                  },
                  {
                    text: 'UNIT',
                    bold: true
                  },
                  {
                    text: 'DESCRIPTION',
                    bold: true
                  },
                  {
                    text: 'SERIAL NUMBER',
                    bold: true
                  }
                ],
                ...allItems,
                ...itemDetailsOfEmployee
              ]
            }
          },
          {
            margin: [0, 10, 0, 10],
            table: {
              layout: 'headerLineOnly',
              headerRows: 1,
              widths: ['33.333%', '33.3333%', '33.333%'],
              body: [
                [
                  {
                    stack: [
                      {
                        text: 'Issued By:',
                        bold: true
                      },
                      {
                        text: `${user.full_name}`,
                        margin: [0, 10, 0, 0]
                      }
                    ],
                    border: noBorder
                  },
                  {
                    stack: [
                      {
                        text: 'Noted By:',
                        bold: true
                      },
                      ...this.notedByEmployees.map(notedByEmployee => ({
                        stack: [
                          {
                            text: notedByEmployee.employee_name,
                            margin: [0, 10, 0, 0],
                            border: [false, false, true, false]
                          },
                          {
                            text: notedByEmployee.position_name,
                            margin: [0, 1, 0, 0]
                          }
                        ]
                      }))
                    ],
                    border: noBorder
                  },
                  {
                    stack: [
                      {
                        text: 'Received By:',
                        bold: true
                      },
                      {
                        text: employeeName,
                        margin: [0, 10, 0, 0]
                      },
                      {
                        text: this.employeeIssuance.position,
                        margin: [0, 1, 0, 0]
                      }
                    ],
                    border: noBorder
                  }
                ]
              ]
            }
          }
        ]
      }
    }

  }
}
